@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 100%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 100%;
  }

  #portfolio {
    width: 100%;
  }
}

@media screen and (max-width: 684px){
  .intro {
    background: url(presentation_graphic-long.png) center center no-repeat;
    margin-top: -50;
    margin-bottom: -160;
    text-align: center;
    -webkit-background-size:410px 400px;
    -moz-background-size:410px 400px;
    background-size:410px 400px;
    -o-background-size:410px 400px;
  }

  .intro-description {
    line-height: 100px;
    position: relative;
    top: 30px;
  }
  
  .arrow-button {
    line-height: 100px;
    position: relative;
    top: 30px;
  }
  
}


